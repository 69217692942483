<template>
  <Layout>
    <mainSection />
  </Layout>
</template>

<script>
export default {
  name: "Home",
  created() {
    setTimeout(() => {
      if (
        typeof this.user_info != "undefined" &&
        this.user_info.personal_info
      ) {
        this.$i18n.locale = this.user_info.personal_info.user_lang;
        localStorage.setItem(
          "user_lang",
          this.user_info.personal_info.user_lang
        );
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("user_lang", "en");
      }
    }, 500);
  },
};
</script>

<script setup>
import { onMounted, reactive, ref } from "vue";
import mainSection from "../components/home/main-section";
import Layout from "../components/home/Layout/Index";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";
import { useStore } from "vuex";

const store = useStore();
const user_id = ref(1);
const fetchUserInfo = () => {
  store.dispatch("profilestore/getProfileInfo", { accountId: user_id });
};
fetchUserInfo();
const user_info = reactive(store.state.profilestore.personal_info);
const { getContacts } = useWidgetConfig();

onMounted(() => {
  getContacts();
  getPaymentsMethods();
});

const getPaymentsMethods = () => {
  return store.dispatch("profilestore/getPaymentsMethods", { accountId: 1 });
};
</script>
